import {Link, MenuItem, Select, Stack, Typography} from '@mui/material'
import {grey} from '@mui/material/colors'
import {ReactComponent as CheckIcon} from 'src/images/check.svg'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import UploadButton from 'src/components/students/UploadButton'
import Month from 'src/entities/Month'

interface FailedReportProps {
  url: string
  fileName: string
}

const FailedReport = observer(({url, fileName}: FailedReportProps) => {
  const mainStore = useMainStore()
  const {reportErrors} = mainStore.studentsStore
  const {downloadFile} = mainStore.azureStorageStore
  const {relevantMonths} = mainStore.institutionStore
  const {currentMonth, setCurrentMonth} = mainStore.studentsStore

  const handleChangeMonth = (event: any) => {
    const selectedMonth = JSON.parse(event.target.value) as Month
    setCurrentMonth(selectedMonth)
  }
  return (
    <Stack direction="column">
      <Select
        sx={{
          left: '20px',
          width: '122px',
          height: '34px',
          minWidth: 'fit-content',
          fontSize: '14px',
          fontWeight: '700',
          backgroundColor:
            'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))',
          alignSelf: 'end'
        }}
        value={currentMonth ? JSON.stringify(currentMonth) : ''}
        onChange={handleChangeMonth}
      >
        {relevantMonths?.map((m, i) => (
          <MenuItem
            sx={{
              color: 'black',
              fontSize: '14px',
              fontWeight: '700'
            }}
            value={JSON.stringify(m)}
            key={i}
          >
            {`${m.name} ${m.year}`}
          </MenuItem>
        ))}
      </Select>
      <Stack alignItems={'center'} spacing={2}>
        <CheckIcon />
        <Typography variant="h5">
          {reportErrors.length > 0 ? 'נתונים לא תקינים' : 'ארעה שגיאה'}
        </Typography>
        <Typography variant="body1" align="center" color={grey[700]}>
          {reportErrors.length > 0
            ? 'שים לב לשגיאות הבאות:'
            : 'ארעה שגיאה בהעלאת הדו”ח.'}
          <br />
          <>
            {reportErrors.map(e => (
              <>
                {e}
                <br />
              </>
            ))}
          </>
          <Link
            underline="always"
            sx={{cursor: 'pointer'}}
            onClick={() => downloadFile(url, fileName)}
          >
            יש להוריד את הדו”ח
          </Link>
          {reportErrors.length > 0 ? ' ולתקן את השגיאות' : ', לבדוק את תקינותו'}
          <br />
          ולהעלות שנית.{' '}
        </Typography>
        <UploadButton
          text={'העלאה'}
          sx={{width: '25%', alignItems: 'center'}}
          variant="contained"
        />
      </Stack>
    </Stack>
  )
})

export default FailedReport

import {Dialog, DialogContent, IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FailedReport from 'src/components/students/FailedReport'

interface FailedReportProps {
  url: string
  fileName: string
  isOpen: boolean
  onClose: () => void
}

const FailedReportModal = ({
  url,
  fileName,
  isOpen,
  onClose
}: FailedReportProps) => {
  return (
    <Dialog open={isOpen}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          left: 6,
          top: 16
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <FailedReport url={url} fileName={fileName} />
      </DialogContent>
    </Dialog>
  )
}

export default FailedReportModal

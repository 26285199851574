import Month from 'src/entities/Month'

const monthNames = [
  'ינואר',
  'פברואר',
  'מרץ',
  'אפריל',
  'מאי',
  'יוני',
  'יולי',
  'אוגוסט',
  'ספטמבר',
  'אוקטובר',
  'נובמבר',
  'דצמבר'
]

const monthsWithId = monthNames.map((name, index) => ({
  id: index + 1,
  name: name
}))

const createYearsArray = () => {
  const currentYear = new Date().getFullYear()
  const startYear = currentYear - 1
  const endYear = currentYear + 5
  const yearsArray = []

  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year)
  }

  return yearsArray
}

const years = createYearsArray()

const getMonths = () => {
  const currentDate = new Date()
  const months = []

  let month = 6
  let year = 2024

  while (
    year < currentDate.getFullYear() ||
    (year === currentDate.getFullYear() && month <= currentDate.getMonth())
  ) {
    months.push(
      new Month({
        id: month + 1,
        name: monthNames[month],
        year: year
      })
    )

    month++
    if (month > 11) {
      month = 0
      year++
    }
  }

  return months
}

const getDefaultMonth = () => {
  const today = new Date()
  const dayOfMonth = today.getDate()
  let month = today.getMonth()

  if (dayOfMonth < 15) month -= 1

  if (month < 0) month = 11 // December of the previous year

  return month + 1
}

const months = getMonths()
const defaultMonthNumber = getDefaultMonth()
const defaultMonth = months.find(m => m.id === defaultMonthNumber)!

export {years, monthsWithId, monthNames, getMonths, defaultMonth}

import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config from 'src/config'

import TeamReportMonth, {
  TeamReportStatusEnum
} from 'src/entities/TeamReportMonth'
import {MainStore} from 'src/store/MainStore'

export class TeamReportStore {
  teamReportsMonth?: TeamReportMonth[]

  get isValidTeamReport() {
    return this.teamReportsMonth?.some(
      t => t.statusId === TeamReportStatusEnum.MatchStudents
    )
  }

  get hasNewTeamReport() {
    return this.teamReportsMonth?.some(
      t =>
        t.statusId === TeamReportStatusEnum.New ||
        t.statusId === TeamReportStatusEnum.InProgress
    )
  }

  get isInValidTeamReport() {
    return this.teamReportsMonth?.some(
      t => t.statusId === TeamReportStatusEnum.InvalidTemplate
    )
  }

  get isMissingTeamReport() {
    return (
      this.teamReportsMonth === undefined || this.teamReportsMonth.length === 0
    )
  }

  get isSuccessLoaded() {
    return this.teamReportsMonth?.some(
      t => t.statusId === TeamReportStatusEnum.SuccessLoaded
    )
  }

  clearTeamReports = () => {
    if (this.teamReportsMonth) this.teamReportsMonth.length = 0
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.studentsStore.currentMonth,
      async currentMonth => {
        if (currentMonth) {
          await this.getTeamReports(currentMonth.id, currentMonth.year)
        } else {
          this.clearTeamReports()
        }
      }
    )

    reaction(
      () => this.teamReportsMonth,
      async teamReportsMonth => {
        if (teamReportsMonth && teamReportsMonth.length > 0) {
          await this.owner.studentsStore.getData(
            this.owner.institutionStore.currentInstitution?.id
          )
        }
      }
    )
  }

  importReport = async (reportId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/TeamReport/${reportId}/import`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (!response.ok) this.owner.setMessage('התהליך נכשל, נסה שוב.')
      return true
    } catch (error) {
      console.error('Failed to import report.')
      return false
    }
  }

  matchReport = async (reportId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/TeamReport/${reportId}/match`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (!response.ok) this.owner.setMessage('התהליך נכשל, נסה שוב.')
    } catch (error) {
      throw new Error('Failed to match report.')
    }
  }

  downloadTemReports = (statusId: number) => {
    const reports = this.teamReportsMonth?.filter(t => t.statusId === statusId)
    reports?.forEach(async r => {
      if (r.document?.url)
        await this.owner.azureStorageStore.downloadFile(
          r.document?.url,
          r.document?.name
        )
    })
  }

  MAX_RETRIES = 1

  getTeamReports = async (
    month: number,
    year: number,
    retryCount = 0
  ): Promise<boolean> => {
    if (!this.owner.institutionStore.currentInstitution?.id) {
      return false
    }
    if (!month || !year) {
      console.error('Invalid month or year provided.')
      return false
    }

    const self = this

    if (retryCount >= this.MAX_RETRIES) {
      console.error('Maximum retries reached. Aborting further processing.')
      return false
    }

    const params: any = {month, year}
    const url = new URL(
      `${config.apiUrl}/TeamReport/Institution/${this.owner.institutionStore.currentInstitution?.id}/byMonth`
    )

    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )

    const MAX_TIME = 5 * 60 * 1000 // 5 minutes in milliseconds
    const INTERVAL_TIME = 30 * 1000 // 30 seconds in milliseconds
    let totalElapsedTime = 0

    const fetchAndProcessReports = async (): Promise<boolean> => {
      try {
        const response = await this.owner.loginStore.fetchWithUser(
          url.toString()
        )
        const data: any[] = await response.json()

        if (!data || data.length === 0) {
          this.clearTeamReports()
          return false
        }

        runInAction(() => {
          this.teamReportsMonth = data
            ?.filter(r => r.statusId !== TeamReportStatusEnum.SuccessLoaded)
            .map(report => new TeamReportMonth(report))
        })

        let isFailed = false

        // Handle reports with "InProgress" status by continuing the interval
        const inProgressReports = data.some(
          r => r.StatusId === TeamReportStatusEnum.InProgress
        )
        if (inProgressReports) {
          console.log('Reports are still in progress. Waiting...')
          return false // Keep the interval running
        }

        // Handle "SuccessLoaded" status reports
        const successReports = data.filter(
          report => report.StatusId === TeamReportStatusEnum.SuccessLoaded
        )
        for (const report of successReports) {
          if (report.teamReportId) {
            try {
              await this.matchReport(report.TeamReportId)
            } catch (e) {
              console.error(`Error matching report ${report.TeamReportId}:`, e)
              isFailed = true
            }
          }
        }

        // Handle "New" status reports
        const newReports = data.filter(
          report => report.StatusId === TeamReportStatusEnum.New
        )
        for (const report of newReports) {
          if (report.TeamReportId) {
            if (!(await this.importReport(report.TeamReportId))) {
              isFailed = true
            }
          }
        }
        if (isFailed && newReports.length > 0) {
          return false
        }

        // If no new or success reports are left, resolve successfully
        if (
          !this.teamReportsMonth?.some(
            t => t.statusId === TeamReportStatusEnum.New
          )
        ) {
          return true
        }
      } catch (error) {
        console.error('Error fetching team reports:', error)
        runInAction(() => {
          this.teamReportsMonth = undefined
        })

        return true
      }

      return false
    }

    // Perform the first fetch immediately
    if (await fetchAndProcessReports()) {
      return true
    }

    // Set up the interval for subsequent attempts
    return new Promise<boolean>(resolve => {
      const intervalId = setInterval(
        async () => {
          totalElapsedTime += INTERVAL_TIME

          if (totalElapsedTime > MAX_TIME) {
            console.log('Maximum time reached, stopping report check.')
            clearInterval(intervalId)

            resolve(true)
            return
          }

          if (await fetchAndProcessReports()) {
            clearInterval(intervalId)
            await self.owner.studentsStore.getData(
              self.owner.institutionStore.currentInstitution?.id
            )
            resolve(true)
          }
        },
        INTERVAL_TIME,
        self
      )
    })
  }
}

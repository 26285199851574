export const institutionDetails = [
  {
    name: 'סוג מוסד',
    key: 'typeId'
  },
  {
    name: 'שם מוסד',
    key: 'name',
    validationRegex: '[A-Za-zא-ת]'
  },
  {
    name: 'ראש כולל/ישיבה ',
    key: 'institutionHeadName',
    validationRegex: '[A-Za-zא-ת]'
  },
  {
    name: 'טלפון ראש כולל/ישיבה',
    key: 'institutionHeadPhone',
    validationRegex: '^(?=\\D*\\d){7,}(\\D*\\d+)*$'
  },
  {
    name: 'רחוב',
    key: 'street',
    validationRegex: '(?=.*[A-Za-zא-ת0-9].*[A-Za-zא-ת0-9])'
  },
  {
    name: 'מספר בית',
    key: 'houseNumber',
    validationRegex: '\\d'
  },
  {
    name: 'עיר',
    key: 'city',
    validationRegex: '(?=.*[A-Za-zא-ת0-9])'
  },
  {
    name: 'מיקוד',
    key: 'zipCode'
  },
  {
    name: 'טלפון',
    key: 'phone',
    validationRegex: '^(?=\\D*\\d){7,}(\\D*\\d+)*$'
  },
  {
    name: 'מקום לימוד',
    key: 'learnLocation',
    validationRegex: '[\\w\\W]'
  },
  {
    name: 'עמותה שייכת למוסד',
    key: 'isAssociationBelongTo'
  }
]

export const associationDetails = [
  {
    name: 'שם עמותה',
    key: 'name',
    validationRegex: '[A-Za-zא-ת]'
  },
  {
    name: 'מספר עמותה',
    key: 'associationId',
    validationRegex: '\\d'
  },
  {
    name: 'רחוב',
    key: 'street',
    validationRegex: '.*[\u0590-\u05FF].*'
  },
  {
    name: 'מספר בית',
    key: 'houseNumber',
    validationRegex: '\\d'
  },
  {
    name: 'עיר',
    key: 'city',
    validationRegex: '(?=.*[A-Za-zא-ת0-9])'
  },
  {
    name: 'מיקוד',
    key: 'zipCode'
  },
  {
    name: 'טלפון',
    key: 'phone',
    validationRegex: '^(?=\\D*\\d){7,}(\\D*\\d+)*$'
  }
]

export const bankDetails = [
  {
    name: 'שם בעל החשבון',
    key: 'accountOwner'
  },
  {
    name: 'בנק',
    key: 'bankId',
    validationRegex: '\\d'
  },
  {
    name: 'סניף',
    key: 'branchId',
    validationRegex: '\\d'
  },
  {
    name: 'מספר חשבון',
    key: 'accountNumber',
    validationRegex: '\\d'
  },
  {
    name: 'חשבון clear shift',
    key: 'clearShiftReference',
    validationRegex: '^(\\d{13}|)$'
  }
]

export const contactDetails = [
  {
    name: 'סוג הזדהות',
    key: 'indificationTypeID'
  },
  {
    name: 'ת.ז.',
    key: 'indificationNo',
    validationRegex: '^(?:\\d{9}|[A-T][0-9]{6,8})$'
  },
  {
    name: 'שם פרטי',
    key: 'firstName',
    validationRegex: '.*[\u0590-\u05FF].*'
  },
  {
    name: 'שם משפחה',
    key: 'lastName',
    validationRegex: '.*[\u0590-\u05FF].*'
  },
  {
    name: 'מייל',
    key: 'email',
    validationRegex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
    fullWidth: true
  },
  {
    name: 'מייל נוסף לעידכון העברת הכספים',
    key: 'additionalEmailForPayment',
    validationRegex: '^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}|)$',
    fullWidth: true
  },
  {
    name: 'טלפון',
    key: 'phone',
    validationRegex: '^(?=\\D*\\d){7,}(\\D*\\d+)*$'
  },
  {
    name: 'פלאפון',
    key: 'cellPhone',
    validationRegex: '^(?=\\D*\\d){7,}(\\D*\\d+)*$'
  },
  {
    name: 'תפקיד במוסד',
    key: 'title',
    validationRegex: '.*[\u0590-\u05FF].*'
  },
  {
    name: 'רחוב',
    key: 'street',
    validationRegex: '.*[\u0590-\u05FF].*'
  },
  {
    name: 'מספר בית',
    key: 'houseNumber',
    validationRegex: '\\d'
  },
  {
    name: 'עיר',
    key: 'city',
    validationRegex: '(?=.*[A-Za-zא-ת0-9])'
  },
  {
    name: 'מיקוד',
    key: 'zipCode'
  }
]

export const auditorDetails = [
  {
    name: 'מבקר',
    key: 'auditor'
  },
  {
    name: 'הערות מבקר',
    key: 'auditNote'
  }
]

import Document from 'src/entities/Document'

export default class TeamReportMonth {
  teamReportId?: number

  teamReportMonthId?: number

  month?: number

  year?: number

  statusId?: number

  document?: Document

  constructor(teamReport: any) {
    this.teamReportId = teamReport.TeamReportId
    this.teamReportMonthId = teamReport.TeamReportMonthId
    this.month = teamReport.Month
    this.year = teamReport.Year
    this.statusId = teamReport.StatusId
    this.document = teamReport.Document
      ? new Document(teamReport.Document)
      : undefined
  }
}

export enum TeamReportStatusEnum {
  New = 1,
  SuccessLoaded = 2,
  InvalidTemplate = 3,
  MatchStudents = 4,
  InProgress = 5
}

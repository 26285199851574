// components
import {SettingsValueProps} from './components/settings/type'

// LAYOUT
// ----------------------------------------------------------------------

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
}
export const ICON = {
  NAV_ITEM: 20,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22
}

export const NAV = {
  W_BASE: 2600,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 150,
  //
  H_DASHBOARD_ITEM: 500,
  H_DASHBOARD_ITEM_SUB: 360,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 300,

  W_ITEM: 400,
  W_ITEM_CLOSED: 250
}

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false
}

export const systemObjects = {
  documentCategories: 1,
  documentStatuses: 2,
  institutionsTypes: 3,
  institutionsStatuses: 4,
  learningShifts: 5,
  paymentStatuses: 6,
  studentReportStatuses: 7,
  studentTypes: 8,
  indificationTypes: 9,
  datotRates: 10,
  studentImportStatuses: 11,
  studentStatuses: 12,
  datotStudentTypes: 13,
  auditStatuses: 14
}

export const roles = {
  secretary: 1,
  manager: 2
}

const config = {
  MUIDataGridLicenseKey: process.env.REACT_APP_MUI_DATA_GRID_LICENSE_KEY || '',
  AADRedirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',
  AADConfig: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
    authority: process.env.REACT_APP_AAD_AUTHORITY || ''
  },
  apiUrl: process.env.REACT_APP_API || '',
  isProduction: process.env.REACT_APP_BRANCH_NAME === 'main',
  isMain: process.env.REACT_APP_BRANCH_NAME === 'main',
  isDevelopment: process.env.NODE_ENV === 'development',
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APPID || '',
  rollbarPostClientItem: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM || '',
  azureBlobStorage: {
    storageName:
      process.env.REACT_APP_STORAGE_NAME ||
      'https://cbpkotil01stg.blob.core.windows.net/',
    containerName:
      process.env.REACT_APP_CONTAINER_NAME || 'institution-documents-s'
  },
  apiCityUrl: process.env.REACT_APP_API_CITY_URL || ''
}

export default config

import {Paper, Stack, Typography, Button, IconButton} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import DataGridWrapper from 'src/components/grid/DataGridWrapperNew'
import {useEffect, useState} from 'react'
import {monthNames} from 'src/utils/month'
import RenderEditLabelCell from 'src/components/grid/RenderEditCell'

import {
  GridColDef,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {LabelColor} from 'src/components/label'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'
import CuiLoading from 'src/components/custom/CuiLoading'
import {format} from 'date-fns'
import SendPaymentEmailDialog from './SendPaymentEmailDialog'
import SendIcon from '@mui/icons-material/Send'
import DownloadIcon from '@mui/icons-material/Download'
import PaymentsToolBar from './PaymentsToolBar'
import {singleSelectFilterFunc} from 'src/components/ui/SingleSelectFilter'
import {ReactComponent as DefaultCircle} from 'src/images/default_circle.svg'

import {ReactComponent as DeleteIcon} from 'src/images/delete.svg'
import DeletePaymentDialog from 'src/components/institutions-payments/DeletePaymentDialog'

const Payments = observer(() => {
  const mainStore = useMainStore()

  const {currentUser, isManager} = mainStore.loginStore
  const {users} = mainStore.usersStore
  const {
    payments,
    decidedPaymentsCount,
    decidedPaymentsSum,
    getPayments,
    paymentsStatuses,
    updatePaymentStatus,
    exportReport,
    sendPaymentEmail,
    setIsInvalidPaymentReportId,
    deletePayment
  } = mainStore.paymentsStore

  const [openSendEmailDialog, setOpenSendEmailDialog] = useState<boolean>(false)

  const editablePaymentsStatusesId = [1, 3]
  const editablePayiedPaymentsStatusesId = [6, 2]
  //הופק דווח
  const editableExportReportStatusesId = [4, 6]

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [deleteRowId, setDeleteRowId] = useState(null)

  const onSave = async () => {
    deleteRowId && (await deletePayment(deleteRowId))
    setOpenDialog(false)
    setDeleteRowId(null)
  }

  const handleDeleteClick = (row: any) => {
    const id = row?.id
    if (id) {
      setDeleteRowId(id)
      setOpenDialog(true)
    }
  }

  const onClose = () => {
    setOpenDialog(false)
    setDeleteRowId(null)
  }

  const getEditableStatus = (statusId: number) => {
    return paymentsStatuses.filter(s => {
      if (editablePayiedPaymentsStatusesId.includes(statusId)) {
        return editablePayiedPaymentsStatusesId.includes(s.id)
      }
      if (editableExportReportStatusesId.includes(statusId)) {
        return editableExportReportStatusesId.includes(s.id)
      }
      return editablePaymentsStatusesId.includes(s.id)
    })
  }

  const onClosePaymentEmailDialog = () => {
    setIsInvalidPaymentReportId(false)
    setOpenSendEmailDialog(false)
  }
  const onExportReport = async () => {
    // dowmload return excel file
    await exportReport()
  }
  const onSavePaymentEmailDialog = async (
    paymentReportId: number | undefined
  ): Promise<void> => {
    if (paymentReportId) {
      const res = await sendPaymentEmail(paymentReportId)
      if (res === true) {
        mainStore.setMessage(
          'שליחת המיילים בתהליך - תתקבל הודעה למייל בסיום הפעולה',
          'info'
        )
        setOpenSendEmailDialog(false)
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'paymentReportId',
      headerName: 'מזהה דו”ח',
      headerAlign: 'center',
      type: 'number',
      align: 'center',
      flex: 1
    },
    {
      field: 'institutionId',
      headerName: 'מזהה מוסד',
      headerAlign: 'center',
      minWidth: 60,
      sortable: true,
      filterable: true,
      type: 'number',
      align: 'center',
      flex: 1
    },
    {
      field: 'institutionName',
      headerName: 'שם מוסד',
      headerAlign: 'center',
      minWidth: 180,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) => row.institution?.name || ''
    },
    {
      field: 'createdAt',
      headerName: 'תאריך החלטה',
      headerAlign: 'center',
      minWidth: 120,
      type: 'date',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) => row?.createdAt,
      valueFormatter: (_, row) => format(row?.createdAt, 'dd.MM.yy')
    },
    {
      field: 'createdBy',
      headerName: 'הוחלט ע”י',
      headerAlign: 'center',
      minWidth: 140,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) => {
        return users?.find(u => u.id === row?.createdBy)?.fullName || ''
      }
    },
    {
      field: 'amount',
      headerName: 'סכום',
      headerAlign: 'center',
      type: 'number',
      minWidth: 140,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        const formattedAmount = new Intl.NumberFormat('en-US').format(
          row.amount
        )
        return `${formattedAmount} ש"ח`
      }
    },
    {
      field: 'month',
      headerName: 'חודש',
      filterable: true,
      sortable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueGetter: (params: any) => monthNames[params - 1] || '',
      valueFormatter: (params: any) => params
    },
    {
      field: 'paymentDate',
      headerName: 'תאריך תשלום',
      minWidth: 120,
      type: 'date',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueGetter: (_, row) => {
        const payDate = row?.paymentReport?.payDate
        return payDate ? new Date(payDate) : null // Return a Date object or null
      },
      valueFormatter: (_, row) => {
        const payDate = row?.paymentReport?.payDate
        if (!payDate) return ''
        const date = new Date(payDate)
        return format(date, 'dd.MM.yy')
      }
    },
    {
      field: 'clearShiftReference',
      headerName: 'חשבון  קלירשיפט',
      headerAlign: 'center',
      minWidth: 120,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) =>
        row.institution.association?.clearShiftReference || ''
    },
    {
      field: 'comment',
      headerName: 'הערות',
      headerAlign: 'center',
      minWidth: 180,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1
    },
    {
      field: 'statusId',
      headerName: 'סטטוס',
      maxWidth: 200,
      sortable: true,
      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueFormatter: (statusId: any) => {
        const status = paymentsStatuses?.find(v => v.id === statusId)
        return status?.description || ''
      },
      renderCell: (params: any) => {
        const status = paymentsStatuses?.find(v => v.id === params.row.statusId)
        return (
          <LabelCol
            text={status?.description || ''}
            color={(status?.color || 'primary') as LabelColor}
          />
        )
      },
      editable: isManager ? true : false,
      renderEditCell: (params: any) => {
        return (
          <RenderEditLabelCell
            value={params.row.statusId}
            values={getEditableStatus(params.row.statusId) || []} // disable select Paid status
            onChange={e => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value
              })
              updatePaymentStatus(params.row, Number(e.target.value))
            }}
          />
        )
      },
      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(paymentsStatuses)
    }
  ]

  if (isManager) {
    columns.push({
      field: 'delete',
      headerName: '',
      filterable: false,
      headerAlign: 'center',
      width: 150,
      align: 'center',
      disableExport: true,
      flex: 1,
      renderCell: (params: any) => {
        return params.row.statusId === 1 || params.row.statusId === 3 ? (
          <IconButton onClick={() => handleDeleteClick(params.row)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )
      }
    })
  }

  const NoRowsOverlay = () => {
    return <div style={{textAlign: 'center', padding: '20px'}}>אין נתונים</div>
  }

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted) {
          getPayments()
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getPayments])

  return (
    <>
      <Stack width={'100%'} height={'100%'} spacing={2}>
        <Typography variant="subtitle2">
          שלום {currentUser?.fullName}
        </Typography>
        <Paper sx={{width: '100%', p: 3}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={'h5'}>מוסדות מאושרים לתשלום </Typography>
            <Stack direction="row" alignItems="center">
              <DefaultCircle style={{marginLeft: '8px'}} />
              <Stack spacing={-1}>
                <Typography variant={'h5'}>הוחלט לתשלום</Typography>
                <Typography>
                  {' '}
                  <b>{decidedPaymentsCount}</b> מוסדות
                </Typography>
                <Typography>
                  {new Intl.NumberFormat('en-US').format(decidedPaymentsSum)}{' '}
                  ש"ח
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                disabled={!isManager}
                variant="contained"
                onClick={() => onExportReport()}
                startIcon={<DownloadIcon sx={{ml: 1, mr: -0.5}} />}
                sx={{
                  fontFamily: 'Heebo',
                  fontWeight: 'bold'
                }}
              >
                יצא דו"ח
              </Button>
              <Button
                disabled={!isManager}
                variant="contained"
                onClick={() => setOpenSendEmailDialog(true)}
                sx={{
                  fontFamily: 'Heebo',
                  fontWeight: 'bold'
                }}
                startIcon={<SendIcon sx={{ml: 1, mr: -0.5}} />}
              >
                שליחת מייל
              </Button>
            </Stack>
          </Stack>
        </Paper>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxHeight: 'calc(100vh - 150px)'
          }}
        >
          {payments && payments.length > 0 ? (
            <DataGridWrapper
              columns={columns}
              rows={payments}
              hideFooter
              getRowHeight={() => 64}
              isCellEditable={(params: any) => {
                return (
                  editablePaymentsStatusesId.includes(params.row.statusId) ||
                  (isManager &&
                    (editablePayiedPaymentsStatusesId.includes(
                      params.row.statusId
                    ) ||
                      editableExportReportStatusesId.includes(
                        params.row.statusId
                      )))
                )
              }}
              filterModel={filterModel}
              disableColumnMenu
              onFilterModelChange={(newValue: GridFilterModel) => {
                setFilterModel(newValue)
              }}
              slots={{
                toolbar: PaymentsToolBar
              }}
              slotProps={{
                panel: {
                  anchorEl: filterButtonEl
                },
                baseSelect: {native: false, defaultValue: ''},
                toolbar: {
                  setFilterButtonEl: setFilterButtonEl,
                  setFilterModel: setFilterModel
                },
                filterPanel: {
                  filterFormProps: {
                    logicOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small'
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {mt: 'auto'}
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {mt: 'auto'}
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small'
                      }
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-panelFooter': {
                      justifyContent: 'flex-start',
                      px: 2.5
                    },
                    '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                      color: (theme: any) => theme.palette.primary.main,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 171, 85, 0.08)'
                      }
                    },
                    '& .MuiDataGrid-filterForm': {p: 2},
                    '& .MuiDataGrid-filterFormLogicOperatorInput': {
                      mr: 1,
                      width: 75
                    },
                    '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                    '& .MuiDataGrid-filterFormOperatorInput': {
                      mr: 1,
                      width: 150
                    },
                    '& .MuiDataGrid-filterFormValueInput': {width: 280},
                    '& .MuiDataGrid-filterFormDeleteIcon': {
                      justifyContent: 'center'
                    }
                  }
                }
              }}
            />
          ) : payments?.length === 0 ? (
            <NoRowsOverlay />
          ) : (
            <CuiLoading />
          )}
        </Paper>

        {openSendEmailDialog && (
          <SendPaymentEmailDialog
            onClose={onClosePaymentEmailDialog}
            onSave={onSavePaymentEmailDialog}
          />
        )}
      </Stack>
      {openDialog && <DeletePaymentDialog onClose={onClose} onSave={onSave} />}
    </>
  )
})

export default Payments

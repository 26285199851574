import {Alert, Button} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'

import {TeamReportStatusEnum} from 'src/entities/TeamReportMonth'
import UploadButton from './UploadButton'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DownloadIcon from '@mui/icons-material/Download'
import {useEffect} from 'react'

const StudentTeamReportsMonth = observer(() => {
  const {currentMonth} = useMainStore().studentsStore

  const {
    getTeamReports,
    teamReportsMonth,
    isValidTeamReport,
    isMissingTeamReport,
    downloadTemReports,
    hasNewTeamReport,
    isInValidTeamReport
  } = useMainStore().teamReportStore

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted) {
          await getTeamReports(currentMonth.id, currentMonth.year)
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getTeamReports, currentMonth])

  return (
    <>
      {isValidTeamReport && (
        <Alert
          severity="success"
          sx={{
            alignItems: 'center',
            '& .MuiAlert-message': {flexGrow: 1},
            '& .MuiAlert-action': {padding: 0},
            '& .MuiAlert-icon': {m: 0, pl: 1}
          }}
          action={
            <>
              <Button
                sx={{ml: 1.5}}
                variant="outlined"
                color="inherit"
                startIcon={<DownloadIcon sx={{ml: 1, mr: -1}} />}
                onClick={() =>
                  downloadTemReports(TeamReportStatusEnum.MatchStudents)
                }
              >
                {' '}
                הורדה{' '}
              </Button>
              {!hasNewTeamReport && (
                <UploadButton
                  sx={{ml: 1.5}}
                  variant="contained"
                  color="success"
                  startIcon={<CloudUploadIcon sx={{ml: 1, mr: -1}} />}
                  text={' העלאת דו”ח תים נוסף'}
                  categoryId={1}
                />
              )}
            </>
          }
        >
          בוצעה הצלבה עם דו”ח תים (
          {
            teamReportsMonth?.filter(
              t => t.statusId === TeamReportStatusEnum.MatchStudents
            ).length
          }{' '}
          דו”חות)
        </Alert>
      )}
      {isInValidTeamReport && !isValidTeamReport && (
        <Alert
          severity="warning"
          sx={{
            alignItems: 'center',
            '& .MuiAlert-message': {flexGrow: 1},
            '& .MuiAlert-action': {padding: 0},
            '& .MuiAlert-icon': {m: 0, pl: 1}
          }}
          action={
            <>
              <Button
                sx={{ml: 1.5}}
                variant="outlined"
                color="inherit"
                startIcon={<DownloadIcon sx={{ml: 1, mr: -1}} />}
                onClick={() =>
                  downloadTemReports(TeamReportStatusEnum.InvalidTemplate)
                }
              >
                {' '}
                הורדה{' '}
              </Button>
              <UploadButton
                sx={{ml: 1.5}}
                variant="contained"
                color="warning"
                startIcon={<CloudUploadIcon sx={{ml: 1, mr: -1}} />}
                text={'העלאת דו”ח תים חדש'}
                categoryId={1}
              />
            </>
          }
        >
          דו”ח תים המעודכן במערכת לא תקין
        </Alert>
      )}

      {isMissingTeamReport && (
        <Alert
          severity="error"
          sx={{
            alignItems: 'center',
            '& .MuiAlert-message': {flexGrow: 1},
            '& .MuiAlert-action': {padding: 0},
            '& .MuiAlert-icon': {m: 0, pl: 1}
          }}
          action={
            <UploadButton
              sx={{ml: 1.5}}
              variant="contained"
              color="error"
              startIcon={<CloudUploadIcon sx={{ml: 1, mr: -1}} />}
              text={'העלאת דו”ח תים'}
              categoryId={1}
            />
          }
        >
          לא קיים דו”ח תים לחודש הנבחר במערכת
        </Alert>
      )}
      {hasNewTeamReport && (
        <Alert
          severity="info"
          sx={{
            alignItems: 'center',
            '& .MuiAlert-message': {flexGrow: 1},
            '& .MuiAlert-action': {padding: 0},
            '& .MuiAlert-icon': {m: 0, pl: 1}
          }}
        >
          הצלבת דו"ח בטעינה, נא להמתין
        </Alert>
      )}
    </>
  )
})
export default StudentTeamReportsMonth

import {Stack, Button, Typography} from '@mui/material'
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

interface DocsToolbarProps extends GridToolbarProps {
  setFilterButtonEl?: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >
  addDoc?: () => void
}
const DocsToolbar = observer(
  ({setFilterButtonEl, addDoc}: DocsToolbarProps) => {
    return (
      <GridToolbarContainer
        sx={{
          backgroundColor: 'white',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Stack direction="row" gap={1} justifyContent="flex-start">
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton ref={setFilterButtonEl} />
        </Stack>

        <Button
          sx={{
            backgroundColor: 'primary.main!important',
            color: 'white !important',
            '&:hover': {
              backgroundColor: '#0A3D56 !important'
            }
          }}
          onClick={addDoc}
          variant="contained"
        >
          <CloudUploadIcon />
          <Typography
            variant="h6"
            sx={{
              pr: '10px'
            }}
          >
            העלאת מסמך
          </Typography>
        </Button>
      </GridToolbarContainer>
    )
  }
)
export default DocsToolbar

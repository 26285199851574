import {
  Alert,
  Box,
  Card,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import {ReactComponent as AccountIcon} from 'src/images/ic_account.svg'
import {useMainStore} from '../../contexts/Main'
import {observer} from 'mobx-react-lite'
import {useEffect, useState} from 'react'

import {ReactComponent as RefundIcon} from 'src/images/ic_fullRefund.svg'
import Month from 'src/entities/Month'
import {defaultMonth} from 'src/utils/month'
import WarningIcon from '@mui/icons-material/Warning'

const PaymentSummary = observer(() => {
  const {currentInstitution, relevantMonths} = useMainStore().institutionStore
  const {getStudentsDashboard, dashboardByMonth} = useMainStore().studentsStore

  const [month, setMonth] = useState<Month | undefined>(defaultMonth)

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted && currentInstitution) {
          getStudentsDashboard(currentInstitution.id)
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getStudentsDashboard, currentInstitution])

  const handleChangeMonth = (event: any) => {
    const selectedMonth = JSON.parse(event.target.value) as Month
    setMonth(selectedMonth)
  }

  const dashboard = month && dashboardByMonth?.[month?.year][month.id]

  return (
    <Card
      sx={{
        p: 2,
        m: 1.5,
        justifyContent: 'space-between',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column', // Change to 'column' to stack content vertically
        gap: 2
      }}
    >
      {/* Header */}
      <Stack width="100%" spacing={2}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            width: '100%'
          }}
        >
          <Typography fontSize={14} fontWeight={500}>
            נתונים לחודש:
          </Typography>
          <Select
            sx={{
              width: '100px',
              height: '34px',
              fontSize: '14px',
              fontWeight: '700',
              backgroundColor:
                'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))'
            }}
            value={month ? JSON.stringify(month) : ''}
            onChange={handleChangeMonth}
          >
            {relevantMonths?.map((m, i) => (
              <MenuItem
                key={i}
                value={JSON.stringify(m)}
                sx={{
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'black'
                }}
              >
                {`${m.name} ${m.year}`}
              </MenuItem>
            ))}
          </Select>
          <AccountIcon />
          <Box sx={{flexGrow: 1}} />
        </Stack>

        {/* Student Summaries */}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexWrap: 'wrap'
          }}
        >
          {/* Individual Student Type Summaries */}
          <StudentTypeSummery
            type={'בחורים'}
            sum={dashboard?.payYeshivaCount?.toString() || ''}
            datotRate={dashboard?.yeshivaRate?.toString() || ''}
            isMatchTeam={dashboard?.isMatchTeam ?? true}
            sumNotMatch={dashboard?.notMatchYeshivaCount || null}
          />
          <StudentTypeSummery
            type={'אברכים חצי יום'}
            sum={dashboard?.payKollelHalfDayCount?.toString() || ''}
            datotRate={dashboard?.kollelHalfDayRate?.toString() || ''}
            isMatchTeam={dashboard?.isMatchTeam ?? true}
            sumNotMatch={dashboard?.notMatchKollelHalfDayCount || null}
          />
          <StudentTypeSummery
            type={'אברכים יום שלם'}
            sum={dashboard?.payKollelFullDayCount?.toString() || ''}
            datotRate={dashboard?.kollelFullDayRate?.toString() || ''}
            isMatchTeam={dashboard?.isMatchTeam ?? true}
            sumNotMatch={dashboard?.notMatchKollelFullDayCount || null}
          />
        </Stack>
      </Stack>

      {/* Divider */}
      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          borderColor: 'black',
          borderStyle: 'dashed',
          borderWidth: '1px',
          width: '100%',
          mx: 2
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Center the icon horizontally
          alignItems: 'center', // Center the icon vertically
          width: '100%'
        }}
      >
        <RefundIcon />
      </Box>

      {/* Data Content */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'stretch',
          flexWrap: 'wrap'
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            flex: '1 1 62px', // Allows components to flex but enforces a minimum width
            minWidth: 50,
            flexDirection: 'column',
            textAlign: 'center' // Centers text within each item
          }}
        >
          <Typography noWrap fontSize={14} fontWeight={500}>
            הצעה עבור חודש {month?.name || ''}
          </Typography>
          <Typography noWrap fontSize={14} fontWeight={500}>
            לתשלום בחודש {dashboard?.payMonth || ''}
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography fontSize={32} fontWeight={700}>
              {new Intl.NumberFormat('en-US').format(dashboard?.payAmount || 0)}
            </Typography>
            <Typography fontSize={12} fontWeight={700}>
              ש"ח
            </Typography>
          </Stack>
        </Stack>

        {/* Previous Differences */}
        {dashboard?.prevDifferences !== -1 && (
          <>
            <Stack
              alignItems="center"
              sx={{
                flex: '1 1 62px', // Allows components to flex but enforces a minimum width
                minWidth: 50,
                flexDirection: 'column',
                textAlign: 'center' // Centers text within each item
              }}
            >
              <Typography noWrap fontSize={14} fontWeight={500}>
                הצעה להפרשים{' '}
                <Typography component="span" fontSize={20} fontWeight={700}>
                  {new Intl.NumberFormat('en-US').format(
                    dashboard?.prevDifferences || 0
                  )}
                </Typography>{' '}
                ש"ח
              </Typography>
              <Typography noWrap fontSize={14} fontWeight={500}>
                הצעה לתשלום כולל הפרשים
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography fontSize={32} fontWeight={700}>
                  {new Intl.NumberFormat('en-US').format(
                    dashboard?.payWithDifferences || 0
                  )}
                </Typography>
                <Typography fontSize={12} fontWeight={700}>
                  ש"ח
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>

      {/* Alert - Positioned at the bottom and centered */}
      <Box display="flex" alignItems="center" justifyContent="center">
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            fontSize: '12px',
            padding: '0',

            lineHeight: 1,
            alignItems: 'center',

            '& .MuiAlert-action': {padding: 0},
            '& .MuiAlert-icon': {margin: 0, paddingLeft: 1}
          }}
        >
          מחושב לפי נתוני חודש {dashboard?.baseOnMonthString || ''}
        </Alert>
      </Box>
    </Card>
  )
})

export default PaymentSummary

interface StudentTypeSummeryProps {
  type: string
  sum: string
  sumNotMatch: number | undefined | null
  datotRate: string
  isMatchTeam: boolean
}
const StudentTypeSummery = observer(
  ({
    type,
    sum,
    datotRate,
    isMatchTeam,
    sumNotMatch
  }: StudentTypeSummeryProps) => {
    return (
      <Stack
        alignItems="center"
        sx={{
          flex: '1 1 62px', // Allows components to flex but enforces a minimum width
          minWidth: 50,
          flexDirection: 'column',
          textAlign: 'center' // Centers text within each item
        }}
      >
        <Typography noWrap fontWeight={500}>
          {type}
        </Typography>
        <Typography fontWeight={500}>לתמיכה</Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography
            fontSize={32}
            fontWeight={700}
            color={theme =>
              isMatchTeam ? theme.palette.grey[800] : theme.palette.error.main
            }
          >
            {isMatchTeam ? sum : sumNotMatch}
          </Typography>

          {!isMatchTeam && sumNotMatch && (
            <WarningIcon
              color="error"
              style={{
                fontSize: 12, // Adjust the size of the icon
                marginRight: 4
              }}
            />
          )}
        </Box>
        {isMatchTeam && sumNotMatch && (
          <Typography
            fontWeight={700}
            fontSize={12}
            color={theme => theme.palette.error.main}
          >
            לא הוצלבו {sumNotMatch}
          </Typography>
        )}

        <Typography mt={1} fontSize={12}>
          תעריף {datotRate}
        </Typography>
      </Stack>
    )
  }
)

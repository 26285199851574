import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Tooltip
} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import {useEffect, useState} from 'react'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'
import CuiLoading from '../custom/CuiLoading'
import {observer} from 'mobx-react-lite'
import InstitutionsToolbar from 'src/components/Institutions/InstitutionsToolbar'
import DataGridWrapper from 'src/components/grid/DataGridWrapper'
import InstitutionNameCol from 'src/components/grid/columnTypes/InstitutionNameCol'
import {heIL} from '@mui/material/locale'
import RenderEditCellSecretaries from 'src/components/grid/RenderEditCellSecretaries'
import {ReactComponent as DeleteIcon} from 'src/images/delete.svg'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteInstitutionsDialog from 'src/components/Institutions/DeleteInstitutionsDialog'
import {LabelColor} from '../label'
import {singleSelectFilterFunc} from 'src/components/ui/SingleSelectFilter'
import InfoIcon from '@mui/icons-material/Info'
import {LabelInputComponent} from '../grid/GridHelper'
import {monthsWithId} from 'src/utils/month'

const options = [
  {id: 'true', text: 'כן', color: 'warning'},
  {id: 'false', text: 'לא', color: 'error'}
]
const equalFilterOperator: GridFilterOperator<any, any, any> = {
  label: 'שווה',
  value: 'שווה',

  headerLabel: 'כפולים שלא טופלו',
  InputComponent: (props: GridFilterInputValueProps) => (
    <LabelInputComponent data={options} multiple={false} {...props} />
  ),
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    return (params: any) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return true // No filtering applied
      }

      const filterValueAsBoolean = filterItem.value.id === 'true'

      // Compare the filter value with the params
      return params === filterValueAsBoolean
    }
  }
}

const InstitutionsPage = observer(() => {
  const mainStore = useMainStore()
  const {currentUser, isManager} = mainStore.loginStore
  const {secretaries, approvePersons} = mainStore.usersStore

  const {
    institutions,
    institutionsTypes,
    institutionsStatuses,
    updateInstitutionsAssignee,
    deleteInstitution,
    exportInstitutionsReport,
    getInstitutions,
    setCurrentInstitution,
    isLoadingExportInstitutionsReport,
    updateApprovePerson
  } = mainStore.institutionStore

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [deleteRowId, setDeleteRowId] = useState(null)

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted) {
          getInstitutions()
          setCurrentInstitution(undefined)
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getInstitutions, setCurrentInstitution])

  const onExportReport = async () => {
    await exportInstitutionsReport()
  }

  const onSave = async () => {
    deleteRowId && (await deleteInstitution(deleteRowId))
    setOpenDialog(false)
    setDeleteRowId(null)
  }

  const handleDeleteClick = (id: any) => {
    setDeleteRowId(id)
    setOpenDialog(true)
  }

  const onClose = () => {
    setOpenDialog(false)
    setDeleteRowId(null)
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'מזהה',
      headerAlign: 'center',
      width: 100,
      type: 'number',
      disableColumnMenu: true,
      align: 'center',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'name',
      headerName: 'שם מוסד',
      headerAlign: 'center',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      renderCell: params => (
        <InstitutionNameCol name={params.row.name} id={params.row.id} />
      ),
      flex: 1
    },
    {
      field: 'typeId',
      headerName: 'סוג המוסד',
      headerAlign: 'center',
      width: 120,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) =>
        row?.typeId
          ? institutionsTypes?.find(v => v.id === row.typeId)?.description
          : ''
    },

    {
      field: 'institutionHeadName',
      headerName: 'ראש כולל/מוסד',
      headerAlign: 'center',
      width: 150,
      type: 'string',
      align: 'center',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'assignUserId',
      headerName: 'מזכירה אחראית',
      headerAlign: 'center',
      width: 180,
      type: 'string',
      align: 'center',
      editable: true,
      flex: 1,
      valueFormatter: (params: any) => {
        return params
          ? secretaries?.find((s: any) => s.id === params)?.fullName
          : ''
      },
      renderCell: (params: any) =>
        params
          ? secretaries?.find((s: any) => s.id === params.row.assignUserId)
              ?.fullName
          : '',
      renderEditCell: (params: any) => {
        return (
          <RenderEditCellSecretaries
            value={params.row.assignUserId}
            secretaries={secretaries || []}
            onChange={async (e: any) => {
              params.api.stopCellEditMode({id: params.id, field: params.field})
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value
              })
              try {
                await updateInstitutionsAssignee(params.row.id, e.target.value)
              } catch (error) {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: params.value
                })
                params.api.updateRows([
                  {id: params.id, assignUserId: params.value}
                ])
              }
            }}
          />
        )
      },
      sortComparator: (v1: any, v2: any) => {
        const name1 = secretaries?.find((s: any) => s.id === v1)?.fullName || ''
        const name2 = secretaries?.find((s: any) => s.id === v2)?.fullName || ''
        return name1.localeCompare(name2)
      }
    },
    {
      field: 'contact',
      headerName: 'מייל',
      headerAlign: 'center',
      width: 240,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      valueGetter: (params: any) => params.email,
      flex: 1
    },
    {
      field: 'statusId',
      headerName: 'סטטוס',
      width: 110,
      sortable: true,
      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueFormatter: (_, row) => {
        const status = institutionsStatuses?.find(v => v.id === row.statusId)
        return status?.description || ''
      },
      renderCell: (params: any) => {
        const status = institutionsStatuses?.find(
          v => v.id === params.row.statusId
        )
        return (
          <LabelCol
            text={status?.description || ''}
            color={(status?.color || 'primary') as LabelColor}
          />
        )
      },
      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(institutionsStatuses)
    },
    {
      field: 'approvePersonId',
      headerName: 'מאשר',
      headerAlign: 'center',
      width: 180,
      type: 'string',
      align: 'center',
      editable: isManager,
      flex: 1,
      valueFormatter: (params: any) => {
        return params
          ? approvePersons?.find((s: any) => s.id === params)?.fullName
          : ''
      },
      renderCell: (params: any) =>
        params
          ? approvePersons?.find(
              (s: any) => s.id === params.row.approvePersonId
            )?.fullName
          : '',
      renderEditCell: (params: any) => {
        return (
          <RenderEditCellSecretaries
            value={params.row.approvePersonId}
            secretaries={approvePersons || []}
            onChange={async (e: any) => {
              params.api.stopCellEditMode({id: params.id, field: params.field})
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value
              })
              try {
                await updateApprovePerson(params.row.id, e.target.value)
              } catch (error) {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: params.value
                })
                params.api.updateRows([
                  {id: params.id, approvePersonId: params.value}
                ])
              }
            }}
          />
        )
      },
      sortComparator: (v1: any, v2: any) => {
        const name1 =
          approvePersons?.find((s: any) => s.id === v1)?.fullName || ''
        const name2 =
          approvePersons?.find((s: any) => s.id === v2)?.fullName || ''
        return name1.localeCompare(name2)
      }
    },
    {
      field: 'isUnhandleDuplicate',
      headerName: 'כפולים שלא טופלו',
      width: 20,
      align: 'right',
      renderCell: params =>
        params?.value === true ? (
          <Tooltip title="כפולים שלא טופלו">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        ),
      flex: 1,

      filterOperators: [equalFilterOperator]
    },
    {
      field: 'monthStatus',
      headerName: 'חודש סטטוס',
      headerAlign: 'center',
      width: 180,
      type: 'string',
      align: 'center',
      editable: false,
      flex: 1,
      valueFormatter: (params: any) => {
        return params
          ? monthsWithId?.find((s: any) => s.id === params)?.name
          : ''
      },
      renderCell: (params: any) => {
        return params
          ? monthsWithId?.find((s: any) => s.id === params)?.name
          : ''
      }
    }
  ]

  if (isManager) {
    columns.push({
      field: 'delete',
      headerName: '',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      disableExport: true,
      flex: 1,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <IconButton onClick={() => handleDeleteClick(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        )
      }
    })
  }
  return (
    <>
      {institutions && institutions.length > 0 ? (
        <Stack width={'100%'} height={'100%'} spacing={2}>
          <Typography variant="subtitle2">
            שלום {currentUser?.fullName}
          </Typography>
          <Paper sx={{width: '100%', p: 2}}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant={'h5'}> רשימת מוסדות </Typography>{' '}
              <Button
                disabled={isLoadingExportInstitutionsReport}
                variant="contained"
                onClick={() => onExportReport()}
                startIcon={<DownloadIcon sx={{ml: 1, mr: -0.5}} />}
                sx={{
                  fontFamily: 'Heebo',
                  fontWeight: 'bold'
                }}
              >
                יצא דו"ח
                {isLoadingExportInstitutionsReport && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontWeight: 'bold',
                      marginTop: -1.5,
                      marginLeft: -1.5
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Paper>
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              p: 2,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              maxHeight: 'calc(100vh - 150px)'
            }}
          >
            <DataGridWrapper
              tabsData={institutionsStatuses!}
              rows={institutions || []}
              // data={institutions}
              localeText={{
                ...heIL,
                toolbarFilters: 'סינון',
                toolbarQuickFilterPlaceholder: 'חיפוש',
                filterPanelColumns: 'שדות',
                filterPanelInputLabel: 'ערך',
                filterPanelInputPlaceholder: 'ערך מסנן'
              }}
              getRowId={(row: any) => row.id}
              columns={columns}
              hideFooter
              getRowHeight={() => 'auto'}
              filterModel={filterModel}
              disableColumnMenu
              onFilterModelChange={(newValue: GridFilterModel) => {
                setFilterModel(newValue)
              }}
              slots={{
                toolbar: InstitutionsToolbar
              }}
              slotProps={{
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom'
                },
                baseSelect: {native: false, defaultValue: ''},
                toolbar: {
                  setFilterButtonEl: setFilterButtonEl,
                  setFilterModel: setFilterModel
                },
                filterPanel: {
                  filterFormProps: {
                    logicOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small'
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {mt: 'auto'}
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {mt: 'auto'}
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small'
                      }
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-panelFooter': {
                      justifyContent: 'flex-start',
                      px: 2.5
                    },
                    '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                      color: (theme: any) => theme.palette.primary.main,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 171, 85, 0.08)'
                      }
                    },
                    '& .MuiDataGrid-filterForm': {p: 2},
                    '& .MuiDataGrid-filterFormLogicOperatorInput': {
                      mr: 1,
                      width: 75
                    },
                    '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                    '& .MuiDataGrid-filterFormOperatorInput': {
                      mr: 1,
                      width: 150
                    },
                    '& .MuiDataGrid-filterFormValueInput': {width: 280},
                    '& .MuiDataGrid-filterFormDeleteIcon': {
                      justifyContent: 'center'
                    },
                    '& .MuiDataGrid-main': {
                      direction: 'rtl',
                      overflowX: 'auto'
                    },
                    '& .MuiDataGrid-scrollbar': {right: 'unset', left: '0'},
                    '& .MuiDataGrid-virtualScroller': {direction: 'ltr'}
                  }
                }
              }}
            />
          </Paper>
        </Stack>
      ) : (
        <CuiLoading />
      )}
      {openDialog && (
        <DeleteInstitutionsDialog onClose={onClose} onSave={onSave} />
      )}
    </>
  )
})

export default InstitutionsPage
